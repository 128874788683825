body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Urbanist", "sans-serif";
}

.root {
  background-color: #f9f9f9;
  font-family: Urbanist, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiDataGrid-toolbarContainer {
  flex-wrap: wrap;
}

.tableHeader {
  background-color: #b930e6;
  color: white;
  font-family: "Urbanist", "sans-serif";
}

.tableHeader:last-child > .MuiDataGrid-columnSeparator {
  display: none;
}

.MuiDataGrid-root {
  border-radius: 8px !important;
}

.MuiDataGrid-columnHeaders {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.hoverPointer {
  cursor: pointer;
}

.cellRed {
  background-color: #e64942;
}

.cellBlue {
  background-color: #589edb;
}

.cellYellow {
  background-color: #ffd117;
}

.cellPurple {
  background-color: #a560c7;
}

.cellGreen {
  background-color: #42a148;
}

.cellGrey {
  background-color: #ccc;
}

.cellOrange {
  background-color: #e67b43;
}

.leadCardSelected {
  border: 2px solid #b930e6;
}

.webLink {
  color: #fff;
  text-decoration: none;
  font-weight: 800;
  margin-left: 8px;
  margin-right: 8px;
}
.mobileLink {
  color: #555;
  text-decoration: none;
  font-weight: 800;
  margin-left: 10;
  margin-right: 10;
}
